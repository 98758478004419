<template>
  <UiPopoverDrawer
    v-model:model-value="isPopupDrawerOpen"
    :classes="{
      popover: {
        body: 'min-w-[340px] max-w-[340px] overflow-hidden rounded-xl leading-tight',
      },
    }"
  >
    <!-- Trigger -->
    <template #trigger>
      <UiButton
        class="rounded-full border text-base font-medium"
        variant="outline"
        @click="
          () => {
            if (isMobile) isPopupDrawerOpen = true
          }
        "
      >
        {{ t("kyc.note.title") }}
      </UiButton>
    </template>

    <!-- Text -->
    <span v-text="hint" />

    <!-- Mobile Drawer -->
    <template #drawer>
      <UiDialogHeader class="mb-0">
        <div class="p-s -mx-6 mb-0 pt-1 md:border-b">
          <UiDialogTitle>
            {{ $t("kyc.note.title") }}
          </UiDialogTitle>
        </div>

        <UiDialogDescription unset-all>
          <span class="block overflow-auto py-5" v-text="hint" />
        </UiDialogDescription>
      </UiDialogHeader>
    </template>
  </UiPopoverDrawer>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = useDisplay()
defineProps<{ hint?: string }>()

const isPopupDrawerOpen = ref<boolean>(false)

const toggleTooltip = () => (isPopupDrawerOpen.value = !isPopupDrawerOpen.value)
</script>
